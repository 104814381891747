.aup-menu {
  [mat-list-item]:active,
  [mat-list-item]:focus,
  [mat-list-item]:hover,
  [mat-list-item].aup-menu-item-active {
    --mdc-list-list-item-container-color: #f3f9fe;
    --mdc-list-list-item-focus-state-layer-color: transparent;
  }

  [mat-list-item]:active .mdc-list-item__primary-text,
  [mat-list-item]:focus .mdc-list-item__primary-text,
  [mat-list-item]:hover .mdc-list-item__primary-text,
  [mat-list-item].aup-menu-item-active .mdc-list-item__primary-text {
    color: $primary-color !important;
  }

  [mat-list-item] mat-icon path[stroke] {
    stroke: #3d4d59 !important;
  }

  [mat-list-item]:active mat-icon path[stroke],
  [mat-list-item]:focus mat-icon path[stroke],
  [mat-list-item]:hover mat-icon path[stroke],
  [mat-list-item].aup-menu-item-active mat-icon path[stroke] {
    stroke: $primary-color !important;
  }

  [mat-list-item] {
    --mdc-list-list-item-label-text-size: 14px;
    --mdc-list-list-item-leading-icon-size: 20px;
    --mdc-list-list-item-label-text-weight: 700;
    --mdc-list-list-item-label-text-color: rgba(0, 0, 0, 0.77);
    --mdc-list-list-item-label-text-font: 'Lato', sans-serif;
    --mat-toolbar-standard-height: 3.5rem;
  }
}

.aup-submenu {
  .aup-menu-button {
    --mdc-list-list-item-label-text-color: $app-blue;
    --mdc-list-list-item-one-line-container-height: 36px;
  }
}

.aup-third-level-menu {
  [mat-list-item].aup-menu-item-active {
    border-right: 4px solid $app-blue;
  }
}

.aup-mobile-sub-menu {
  .aup-menu-button[mat-list-item] {
    --mdc-list-list-item-label-text-color: $app-blue;
    --mdc-list-list-item-one-line-container-height: 48px;
  }
  [mat-list-item]:not(.aup-menu-button) {
    border: 1px solid $app-gray;
    --mdc-list-list-item-one-line-container-height: 56px;
    .mat-mdc-list-item-unscoped-content {
      display: flex;
      justify-content: space-between;
      --mdc-list-list-item-label-text-font: 'Arial', sans-serif;
      svg {
        width: 24px;
        height: 24px;
      }
    }
  }
}
.accordion-menu {
  mat-expansion-panel {
    --mat-expansion-header-expanded-state-height: 48px;
    --mat-expansion-header-hover-state-layer-color: transparent;
    --mdc-list-list-item-label-text-size: 14px;
    --mdc-list-list-item-leading-icon-size: 20px;
    --mdc-list-list-item-label-text-weight: 700;
    --mdc-list-list-item-label-text-color: rgba(0, 0, 0, 0.77);
    --mdc-list-list-item-label-text-font: 'Lato', sans-serif;
    --mat-expansion-header-indicator-color: rgba(0, 0, 0, 0.77);
    .mat-expansion-panel-header {
      padding: 0 16px;
    }
    mat-icon {
      svg {
        height: 20px;
        width: 20px;
      }
    }
    .mat-expansion-indicator::after {
      border-color: rgba(0, 0, 0, 0.77);
    }
    .mat-expansion-panel-body {
      padding: 0 16px 16px;
    }
  }
}
.aup-left-menu-item {
  span {
    text-align: left !important;
  }
}
