@use '@angular/material' as mat;
@import 'material-colors';

$app-typography-config: mat.define-typography-config(
  $font-family: 'Lato',
  $headline-1: mat.define-typography-level(112px, 112px, 300, $font-family: 'Lato', $letter-spacing: normal),
  $headline-2: mat.define-typography-level(56px, 56px, 700, $font-family: 'Lato', $letter-spacing: normal),
  $headline-3: mat.define-typography-level(45px, 48px, 700, $font-family: 'Lato', $letter-spacing: normal),
  $headline-4: mat.define-typography-level(34px, 40px, 700, $font-family: 'Lato', $letter-spacing: normal),
  $headline-5: mat.define-typography-level(24px, 32px, 700, $font-family: 'Lato', $letter-spacing: normal),
  $headline-6: mat.define-typography-level(24px, 28px, 400, $font-family: 'Lato', $letter-spacing: normal),
  $subtitle-1: mat.define-typography-level(20px, 28px, 700, $font-family: 'Lato', $letter-spacing: normal),
  $subtitle-2: mat.define-typography-level(15px, 24px, 400, $font-family: 'Arial', $letter-spacing: normal),
  $body-1: mat.define-typography-level(16px, 24px, 500, $font-family: 'Arial', $letter-spacing: normal),
  $body-2: mat.define-typography-level(14px, 18px, 400, $font-family: 'Arial', $letter-spacing: normal),
  $caption: mat.define-typography-level(12px, 20px, 400, $font-family: 'Lato', $letter-spacing: normal),
  $button: mat.define-typography-level(14px, 16px, 700, $font-family: 'Lato', $letter-spacing: normal),
);

h3 {
  margin-bottom: 0px !important;
}

.text-gray {
  color: $title-color;
}

.text-blue {
  color: $primary-color;
}

h3,
p {
  margin-bottom: 0px !important;
}

.mat-typography {
  h4.without-margin {
    margin-bottom: 0;
  }
}

p {
  font-family: 'Arial', sans-serif;
}
