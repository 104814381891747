@use '@angular/material' as mat;
@include mat.core();

@import 'material-typography';
@import 'material-colors';
@import 'material-overrides';

// $my-primary: mat.define-palette(mat.$indigo-palette, 500);
// $my-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// create theme (use mat-dark-theme for themes with dark backgrounds)
$app-theme: mat.define-light-theme(
  (
    color: (
      primary: $app-theme-primary,
      accent: $app-theme-accent,
      warn: $app-theme-warn,
    ),
    typography: $app-typography-config,
    density: 0,
  )
);

@include mat.all-component-typographies($app-typography-config);

.app-theme {
  @include mat.all-component-themes($app-theme);
}
