.app-theme {
  form {
    mat-form-field {
      mat-error.mat-mdc-form-field-error {
        color: $notification-error-border;
      }
      &.mat-form-field-invalid,
      &.mat-form-field-invalid mat-mdc-text-field-wrapper:hover {
        .mat-mdc-form-field-flex {
          .mdc-notched-outline {
            .mdc-notched-outline__leading,
            .mdc-notched-outline__notch,
            .mdc-notched-outline__trailing {
              border-color: $notification-error-border !important;
            }
          }
        }
      }
    }
  }
}
