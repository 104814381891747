@import '/src/styles/vendors/material/material-colors';

* {
  box-sizing: border-box;
  outline: none;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

a:not(.mdc-button):not(.mat-mdc-menu-item) {
  color: $primary-color !important;

  &:hover {
    color: $app-primary-dark !important;
  }
}

aup-sticky-banner {
  position: sticky;
  bottom: 0;
  z-index: 999999;
}
