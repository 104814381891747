mat-button-toggle.aup-mat-button-toggle-wrap-text {
  button {
    span.mat-button-toggle-label-content {
      text-wrap: pretty;
      line-height: 1.4375rem;
    }
  }
}
mat-button-toggle-group.aup-appearance {
  border-color: #1474bd;
  mat-button-toggle.mat-button-toggle {
    border-color: #1474bd !important;
    &:hover {
      background: #f3f9fe;
    }
    &.mat-button-toggle-checked {
      background: #f3f9fe;
      button {
        span {
          color: $app-blue;
        }
      }
    }
  }
}
