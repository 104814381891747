.aup-tabs {
  .mat-mdc-tab {
    //border-bottom: 4px solid $app-blue;
    &:not(.mdc-tab--active) {
      border-bottom: 4px solid $app-gray;
    }
    &.mdc-tab--active .mdc-tab-indicator__content--underline {
      border-top-width: 4px;
    }
  }
  &-short-tabs {
    .mat-mdc-tab {
      flex-grow: 0 !important;
    }
  }
}
