@use '@angular/material' as mat;
@import 'mat-palette-generator.scss';

$primary-color: #1474bd;
$warn-color: #f5a623;

$primary-color-base: 500;
$primary-color-light: 200;
$primary-color-dark: 900;
$app-theme-primary: mat.define-palette(
  createPalette($primary-color),
  $primary-color-base,
  $primary-color-light,
  $primary-color-dark
);

$accent-color-base: 500;
$accent-color-light: 200;
$accent-color-dark: 900;
$app-theme-accent: mat.define-palette(
  createPalette($primary-color),
  $accent-color-base,
  $accent-color-light,
  $accent-color-base
);

$warn-color-base: 500;
$warn-color-light: 200;
$warn-color-dark: 900;
$app-theme-warn: mat.define-palette(
  createPalette($warn-color),
  $warn-color-base,
  $warn-color-light,
  $warn-color-dark
);

// Colors
$app-primary-base: mat.get-color-from-palette($app-theme-primary, $primary-color-base);
$app-primary-light: mat.get-color-from-palette($app-theme-primary, $primary-color-light);
$app-primary-dark: mat.get-color-from-palette($app-theme-primary, $primary-color-dark);

$app-white: rgb(255, 255, 255);
$app-black: rgb(0, 0, 0);
$title-color: #666;

$app-green: #09800f;
$app-orange: #e36306;
$app-blue: #1474bd;
$app-gray: #dddddd;
$background-gray: #f2f2f1;
$app-danger: #f0a9b2;
$app-dark-gray: #222;
$app-light-green: #9cd89f;
$app-red: #d0021b;

$notification-success-border: $app-green;
$notification-success-bg: #f3fef4;
$notification-info-border: #1183a7;
$notification-info-bg: #f3fbfe;
$notification-warning-border: #f5a623;
$notification-warning-bg: #fefaf3;
$notification-error-border: $app-red;
$notification-error-bg: #fff2f4;
$sticky-banner-shadow: #00000026;
