button.aup-action-button {
  border: 2px solid $app-blue;
  padding: 0.5rem 1rem;
  height: 2.5rem !important;
  mat-icon {
    svg {
      path {
        fill: $app-blue;
      }
    }
  }
}

button.mdc-button,
a.mdc-button {
  border-radius: 2px !important;
}

button.mdc-button.mat-secondary {
  border: 2px solid $app-blue;
  color: $app-blue;
}
